import axios from 'axios';

async function Put(endpoint, data){
  const config = {
    headers: {
      'Content-Type': 'application/json',
    }
  };

  try {
    const response = await axios.put(endpoint, data, config);
    return response.data;
  } catch (error) {
    console.error('Error occurred while making PuT request:', error);
    throw error;
  }
}

export default Put;