import { React, useState, useEffect, useRef } from 'react';
import './Navbar.css'
// import defaultImage from '../../assets/images/white.png';

const Navbar = ( { userProfile }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

    // const [cognitoUser, setcognitoUser] = useState(null);
    // const [role, setRole] = useState(null);

    const hamburgerRef = useRef();
    const navRef = useRef();

    // const pictureFromStorage = localStorage.getItem('picture');

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener("resize", handleResize);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isHamburgerOpen]);

    const handleClickOutside = (event) => {
        if (hamburgerRef.current && !hamburgerRef.current.contains(event.target) 
            && navRef.current && !navRef.current.contains(event.target)) {
            setIsHamburgerOpen(false);
        }
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    const toggleHamburger = () => {
        setIsHamburgerOpen(!isHamburgerOpen);
    };

    // useEffect(() => {
    //     const fetchUserData = async () => {
    //         if (userProfile) { // Check if userProfile is not null
    //             try {
    //                 const user = JSON.parse(userProfile);
    //                 // console.log(userProfile);
    //                 setcognitoUser(user);
    //             } catch (e) {
    //                 console.error('User profile is not a valid JSON string:', e);
    //             }
    //         }
    //         else {
    //             // const user = JSON.parse(localStorage.getItem('user')); 
    //             // console.log("user Profile must be null");
    //             // setcognitoUser(user);
    //         }
    //     }

    //     fetchUserData(); // Fetch once on load

    // }, [userProfile]);


    return (
        <nav>
        {isMobile && <button ref={hamburgerRef} onClick={toggleHamburger} className='hamburger'>&#9776;</button>}            
        <ul ref={navRef} className={(isMobile && isHamburgerOpen) ? 'navbar-mobile' : ''} 
                style={{ display: (isMobile && !isHamburgerOpen) ? 'none' : '' }}>
                
                {/* {isMobile && (cognitoUser ? (
                  <li>       
                    <a href="/userAccount" className="dropbtn">
                    <div>
                        {cognitoUser && 
                            <img src={cognitoUser.picture || defaultImage} 
                                alt="User" 
                                style={{
                                    marginRight: '10px',
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px',
                                    objectFit: 'cover',
                                    verticalAlign: 'middle'
                                }} 
                                onError={(e) => {
                                    console.error("Error loading image:", e);
                                    if (e.target.src === (cognitoUser.picture)) {
                                        e.target.src = defaultImage;
                                    }
                                    else {
                                        e.target.style.display = 'none';
                                    }
                                }}
                            />
                        }
                        <span>{(cognitoUser && cognitoUser.given_name) ? cognitoUser.given_name : cognitoUser.email}</span>
                    </div>
                    </a>  
                  </li>
                ) : (
                  <li>
                    <a href="/login" className='signup'>Sign Up/Login</a>
                  </li>
                ))}  */}
                <li>
                    <a href="/">Home</a>
                </li>
                {/* {
                    cognitoUser  && role === 'admin' && (
                        <li>
                            <a href="/intake">Intake</a>
                        </li>
                    )
                } */}
                {!isMobile && (
                    <li>
                        <a href="/#about">About Us</a>
                    </li>
                )}
                <li>
                    <a href="/services">Help Out</a>
                </li>
                {/* <li>
                    <a href="/petfinder">Furbabies</a>
                </li> */}
                {!isMobile && (
                    <li className="dropdown">
                        <a href="/waystogive" className="dropbtn">Ways to Give</a>
                        <div className="dropdown-content">
                            <a href="/waystogive">Donate</a>
                            <a href="https://www.amazon.com/hz/wishlist/ls/1AF2KL6LHTS3V?ref_=wl_share" target="_blank" rel="noopener noreferrer">Wishlist</a>
                        </div>
                    </li>
                )}
                <li className='dropdown-link'>
                    <a href="/waystogive">Donate</a>
                </li>
                
                <li className='dropdown-link'>
                    <a href="/adopt">Adopt</a>
                </li>
                <li className='dropdown-link'>
                    <a href="/foster">Foster</a>
                </li>
                <li className='dropdown-link'>
                    <a href="https://www.amazon.com/hz/wishlist/ls/1AF2KL6LHTS3V?ref_=wl_share" target="_blank" rel="noopener noreferrer">Wishlist</a>
                </li>
                {/* <li>
                    <a href="/shop">Shop</a>
                </li> */}
                <li>
                    <a href="/faq">FA<span className="q">Q</span></a>
                </li>
                <li>
                    <a href="/contact">Contact Us</a>
                </li>
                              
                
                {/* {!isMobile && (cognitoUser ? (
                  <li className="dropdown float-right">       
                    <a href="/userAccount" className="dropbtn">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {cognitoUser && 
                        <img src={cognitoUser.picture ? cognitoUser.picture : defaultImage} 
                            alt="User" 
                            style={{
                                marginRight: '10px',
                                borderRadius: '50%',
                                height: '25px',
                                width: '25px',
                                objectFit: 'cover',
                                verticalAlign: 'middle'
                            }} 
                            onError={(e) => {
                                console.error("Error loading image:", e);
                                e.target.style.display = 'none';
                            }}
                        />
                        }
                      <span>{(cognitoUser && cognitoUser.given_name) ? cognitoUser.given_name : cognitoUser.email}</span>
                      </div>
                    </a>  
                    <div className="dropdown-content">
                      <a href="/logout">Sign-out</a>
                    </div>
                  </li>
                ) : (
                  <li className="float-right">
                    <a href="/login" className='signup'>Sign Up/Login</a>
                  </li>
                ))}           */}
            </ul>
        </nav>
    );
};

export default Navbar;