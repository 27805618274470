import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Products.css';

// Import images
import instock1 from '../../assets/images/products/instock1.jpg';
import instock2 from '../../assets/images/products/instock2.jpg';

// Create an object for the images
const images = {
  'instock1': instock1,
  'instock2': instock2,
};

function Products() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    axios.get('https://azad20j2uc.execute-api.us-east-1.amazonaws.com/prod/dynamo?table_name=kkl-amzn-products')
      .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  return (
    <div className="products">
        <h1>Products</h1>
      {products.map(product => (
        <div key={product.id} className="product">

          {/* Use the image name to get the corresponding image from the object */}
          <img src={images[product.image_name]} alt={product.image_name} style={{ width: '200px', height: '200px'}}/>          
          <h2>{product.name}</h2>
          <p>{product.description}</p>
          <p>{product.price}</p>
        </div>
      ))}
    </div>
  );
}

export default Products;