import './Volunteer.css'
import logo from '../../assets/images/transparent_logo.png';
import Viewpdf from '../Viewpdf/Viewpdf';
import volunteer_form from '../../assets/pdfs/12InchTails_Volunteer_Registration.pdf';

function Volunteer() {
  return (
    <>
    <div className="volunteer-bgimg">
    <div className="volunteer-div">
      <div className='content-wrapper'>
        <h1>Volunteer</h1>
        <p>
          At 12 Inch Tails, we're on a never-ending quest for the bravest souls willing to dive into the fur-filled frenzy of feline care! Our cats demand daily tributes of cuddles, clean living quarters, manicured claws, and the finest of cage accommodations. They're not just looking for volunteers; they're recruiting cat whisperers, furball wranglers, and litter box champions.
        </p>
        <i> <b>Register to Volunteer</b></i> <strong>(click below)</strong>. 
        <p>
        <Viewpdf filename={volunteer_form} />
        </p>
        <p>
          But wait, there's more! Ever fancied yourself as a cat chauffeur? Now's your chance to shine, driving our VIPs (Very Important Pussycats) to their spa appointments, glamour shots, and, of course, to meet their adoring fans at adoption events.
        </p>
        {/* <p>
          And for the creatives among us, we're searching for the Spielberg of cat cinema, the Shakespeare of shelter stories, and the Picasso of pussycat portraits to help our whiskered wonders find their forever homes.
        </p> */}
        <p>
          From fundraising fiestas to educating the masses on the art of trap-neuter-return, there's a role for everyone. So, if you're ready to join the ranks of the elite, the few, the proud, the cat volunteers, 
           <a className="all-a" href="/contact"> contact us!</a> Unleash your inner cat person and become a part of our purr-fect team today!
        </p>
        <p>
          <img src={logo} style={{ width: '300px', height: '300px'}} alt="logo" />
        </p>
      </div>
      </div>
      </div>
    </>
  )
}

export default Volunteer;