import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Welcome.css';

function Welcome() {

  const navigate = useNavigate();

  const [storedUser, setStoredUser] = useState(null);

  useEffect(() => { 
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        setStoredUser(JSON.parse(storedUser));
    }
  },[]);

  useEffect(() => {
    if (storedUser) {
      setTimeout(() => { navigate('/home'); }, 5000);
    }
  }, [storedUser, navigate]);


  return (
     <div className="welcome-bgimg">
         <div className="welcome-div">
             {storedUser ? (<><h1>Welcome { storedUser.name }</h1>
             <p>Thank you for visiting 12 Inch Tails!</p></>) : null}
             <p>Click <a href="/home">here</a> if you are not automatically redirected to the home page in 5 seconds.</p>
         </div>   
     </div>  
   );
}

export default Welcome;