import Products from '../Products/Products';
import './Shop.css'

function Shop() {
  return (
      <>
      <Products />
      </>
  )
}

export default Shop;
