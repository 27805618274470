import './Form.css';
// import Button from '../Button/Button.js';
import DropDownMenu from '../DropDownMenu/DropDownMenu.js';
// import CheckboxGroup from '../CheckBoxGroup/CheckBoxGroup.js';
// import DateTimeSelect from '../DateTimeSelect/DateTimeSelect.js';
import IntakeHelper from '../Helpers/IntakeHelper/IntakeHelper.js';
import Buttonlink from '../Buttonlink/Buttonlink.js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from "react-oidc-context";
import { breedOptions, colorOptions, estimatedAgeOptions, sexOptions } from './formOptions.js';

const AdoptionFormComponenent = () => {

  const navigate = useNavigate();

  const auth = useAuth();

  const [userEmail, setUserEmail] = useState('');  // Initialize state
  const[isMobile, setIsMobile] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  // const [applicationStatus, setApplicationStatus] = useState('incomplete');  

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      // console.log(auth.user);
      // localStorage.setItem('user', JSON.stringify());
      setUserEmail(auth.user?.profile.email);
    }
  }, [auth]);

     // Detect mobile device on component mount
  useEffect(() => {
    setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  }, []);

    const [catName, setCatName] = useState('');
    const [breed, setBreed] = useState('');
    const [color, setColor] = useState('');
    const [sex, setSex] = useState('');
    const [estimatedAge, setEstimatedAge] = useState('');
    const [createdAtTime, setCreatedAtTime] = useState('');
    const [username, setUsername] = useState('');
    const [fullname, setFullName] = useState('');
    const [referenceName, setReferenceName] = useState('');
    const [vetName, setVetName]  = useState('');
    const [signature, setSignature] = useState('');
    
    useEffect(() => {
        const currentDateTime = moment().tz('America/New_York').format('YYYY-MM-DDTHH:mm');
        setCreatedAtTime(currentDateTime);
        setUsername(userEmail);
    }, [userEmail]);

    const handleSubmit = async (event) => {
      event.preventDefault();

      const items = {
          catName,
          breed,
          color,
          sex,
          createdAtTime,
          username,
          estimatedAge,
          fullname,
          referenceName,
          vetName,
          // applicationStatus,
          signature
      };

      try {
        // const response = await IntakeHelper.postIntake(items);
        await IntakeHelper.postIntake(items);
        toast.success('Form submitted successfully! Check your email for a response');
        navigate('/home');
        // console.log(response)
      } catch (error) {
          toast.error('Error adding intake. Please try again.');
          // console.log('Error posting intake data:', error);
      }

      // navigate('/intakes');
      // window.location.reload();
  };

  return (
    <div className="AdoptionForm">
      {
            (!auth || !userEmail) ? 
            <>
            
            <div>
              <div style={{ display: 'flex', justifyContent: 'center'}}>
                <p><Buttonlink text="Login/Signup" href="/login" /></p>
              </div>
              <br></br>
              <div>
                <h1>You must be logged in to view this page.</h1>
              </div>
            </div>
            </> :
            <>
      
      <form onSubmit={handleSubmit}>

        <div>
          <h3>You are logged in as {userEmail}</h3>
          <br />
          <div className="progress-div">
              <div className="progress-bar" style={{ '--current-page': currentPage }}></div>
          </div>
          <div>
            <p className='note'>Page {currentPage} of 6</p>
            <p className='note'><Buttonlink href='/adopt' text='Exit Without Saving'/></p>
          </div>
          { currentPage === 1 && (
            <div>
              <h1>Animal Information</h1>
              <label>Cat Name(s):</label><input type="text" value={catName} onChange={(e) => setCatName(e.target.value)} id="catName"/>
              <div className="note">
                <span>See our list of <a href='petfinder' target={isMobile ? '_self' : '_blank'}>available pets here.</a></span>
              </div>
              <br/>
              <DropDownMenu options={breedOptions} defaultValue={breed} label="Breed" onSelectedChange={(choice) => { setBreed(choice);}}  id="breed" />
              <DropDownMenu options={colorOptions} defaultValue={color} label="Color" onSelectedChange={(choice) => { setColor(choice);}} id="color" />
              <DropDownMenu options={sexOptions} defaultValue={sex} label="Sex" onSelectedChange={(choice) => { setSex(choice);}} id="sex" />
              <DropDownMenu options={estimatedAgeOptions} defaultValue={estimatedAge} label="Estimated Age" onSelectedChange={(choice) => { setEstimatedAge(choice);}} id="estimatedAge" required={false} />
              <div className="button-container">
                <button className="next-prev-button" onClick={nextPage}>Next</button> 
              </div>
            </div>
          )}
          { currentPage === 2 && ( 
            <div>
              <h1>Adopter Information</h1>
              <label>Full Name:</label><input type="text" value={fullname} onChange={(e) => setFullName(e.target.value)} id="fullname"/>
              <label>Email:</label><input type="text" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} id="email"/>
              <div className="button-container">
                <button className='next-prev-button' onClick={previousPage}>Previous</button>
                <button className='next-prev-button' onClick={nextPage}>Next</button>
              </div>
            </div>
          )}
          { currentPage === 3 && ( 
            <div>
              <h1>Personal Reference</h1>
              <label>Name:</label><input type="text" value={referenceName} onChange={(e) => setReferenceName(e.target.value)} id="referenceName"/>
              <div className="button-container">
                <button className='next-prev-button' onClick={previousPage}>Previous</button>
                <button className='next-prev-button' onClick={nextPage}>Next</button>
              </div>
            </div>
        )}
          { currentPage === 4 && (
          <div>
            <h1>Veterinarian Information</h1>
            <label>Current Veterinarian:</label><input type="text" value={vetName} onChange={(e) => setVetName(e.target.value)} id="vetName"/>
            <div className="button-container">
              <button className='next-prev-button' onClick={previousPage}>Previous</button>
              <button className='next-prev-button' onClick={nextPage}>Next</button>
            </div>
          </div>
        )}
          { currentPage === 5 && (
        <div>
          <h1>Housing and Pet Ownership Information</h1>
          <div className="button-container">
            <button className='next-prev-button' onClick={previousPage}>Previous</button>
            <button className='next-prev-button' onClick={nextPage}>Next</button>
          </div>
        </div>
        )}
          { currentPage === 6 && (
        <div>
          <h1>Acknowledgments And Agreements</h1>
          <div className="button-container">
            <label>Sign Here (type name):</label><input type="text" value={signature} onChange={(e) => setSignature(e.target.value)} id="signature"/>
            <button className='next-prev-button' onClick={previousPage}>Previous</button>
          </div>
          <br/>
          
        </div>
          )}
        </div>
        <br />
        <br />
        { currentPage !== 6 ? (
          <div className="save-sub-button">
            <button type='submit' style={{backgroundColor:'#eae6e5'}}>Save and Exit</button>
          </div>
        ) : (
          <div className="save-sub-button">
            <button type='submit' style={{backgroundColor:'#eae6e5'}}>Submit</button>
          </div>
        )}
          <br />
          <br />
          <br />
          <br />
        </form>
      </>
        }
    </div>
  );
}

export default AdoptionFormComponenent;