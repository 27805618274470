export const speciesOptions = ["Cat", "Dog", "Other"];
export const breedOptions = ["", "Domestic Longhair", "Domestic Mediumhair", "Domestic Shorthair", "Maine Coon", "Siamese", "Other"];
export const catTypes = ["", "House Cat", "Feral", "Barn Cat", "TNR", "Other"]
export const colorOptions = ["", "Black", "Blue", "Blue Cream", "Brown", "Calico", "Chocolate", "Cinnamon", "Cream", "Fawn", "Gray", "Lilac", "Orange", "Piebald", "Silver", "Smoke", "Tabby", "Tortoiseshell", "Tuxedo", "Other"];
export const intakeOptions = ["", "Found", "Surrendered", "Transfer", "Other"];
export const tabbyTypeOptions = ["", "Classic Tabby", "Mackerel Tabby", "Patched Tabby (Torbie)", "Spotted Tabby", "Ticked Tabby"];
export const estimatedAgeOptions = ["", "Kitten", "Young Adult", "Adult", "Senior"];
export const sexOptions = ["", "Male", "Female", "Unknown"];
export const expectedWeightOptions = Array.from({length: 31}, (_, i) => i); // Creates array with numbers from 0 to 30.
export const sizeOptions = ["", "Small", "Medium", "Large"];
export const vaccinationOptions = ["Rabies", "FVRCP (Feline Viral Rhinotracheitis, Calicivirus, Panleukopenia)", "FeLV (Feline Leukemia Virus)", "FIP (Feline Infectious Peritonitis)", "Other (please specify)"];
export const treatmentOptions = ["Flea Prevention", "Tick Prevention", "Deworming", "Heartworm Prevention", "Ear Mite Treatment", "FIV/FeLV Testing", "Other (please specify)"];
export const dietaryNeedsOptions = [
  "Prescription Diet",
  "Allergies (please specify)",
  "Weight Management",
  "Age-Specific Diet (Kitten, Senior, etc. please specify)",
  "Other Dietary Restrictions (please specify)",
];

export const medicalNeedsOptions = [
  "Chronic Illness (please specify)",
  "Mobility Issues",
  "Regular Medication (please specify)",
  "Post-Surgical Care Requirements",
  "Vision Impairment",
  "Hearing Impairment",
  "Requires Regular Veterinary Check-ups (please specify)",
  "Other Medical Conditions (please specify)",
];

export const behavioralEmotionalNeedsOptions = [
  "Anxiety Management",
  "Special Training Requirements",
  "Needs a Quiet Environment",
  "Not Suitable for Homes with Small Children",
  "Not Suitable for Homes with Other Pets",
  "Other Behavioral Needs (please specify)",
];

export const otherSpecialNeedsOptions = [
  "Grooming Requirements",
  "Environmental Adjustments",
  "Frequent Monitoring or Special Attention Required",
  "Other (please specify)",
];
// Array for Behavioral Traits
export const behavioralObservationsOptions = [
  "Friendly",
  "Shy",
  "Aggressive",
  "Good with Children",
  "Good with Other Animals",
  "Playful (please specify)",
  "Laid Back",
  "Independent",
  "Vocal",
  "Nervous / Anxious",
  "Curious",
  "Affectionate",
  "Scratcher (Furniture, etc.)",
  "Litter-Trained",
  "Special Needs (e.g., dietary, medical)",
  "Leash-Trained (for walks)",
  "Likes to be Held / Lap Cat",
  "Territorial",
  "Enjoys Outdoors",
  "Prefers Solitude",
  "Has a History of Escaping",
  "Interacts Well with Other Cats",
  "Interacts Well with Dogs"
];
