import './Petfinder.css'

function Petfinder() {
    
    return (
        <>
          <pet-scroller
              type='[]'
              age='[]'
              limit="24"

              status="adoptable"
              petlisttitle=""
              organization="NJ1046"
              apibase="https://api.petfinder.com"
              petfinderurl="https://www.petfinder.com"
              accesstoken="eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiJtd1NmUDQ1SEpPckFSS0RkVGM1M3JGSjNHVTJIdnk0SUxVSGR5Y3NnNGpURzRCVHIzdCIsImp0aSI6Ik4xTFU3ODlobHBJbHA3M3NBWHVramMyR1NtbXZyOXJ5bzRaMHFac2YiLCJpYXQiOjE3MzM2NjMxNDIsIm5iZiI6MTczMzY2MzE0MiwiZXhwIjo0ODg5MzM2NzQyLCJzdWIiOiIxODIyMzI5MiIsInNjb3BlcyI6W119.uauZXp1VOZe6njDR3m0Z5XHIKwMDGBbGdY0kn4ji98Oa2E_tCfZmlbE0p07765i1rVVVoaXr945zNJBRFSbg3NR6S4bpBmuAtOMiRKVZ-lDaeECBTAwDzLpWyGVhv5GjSt-Vzupb6f_VbcxalWxscqoq5m7Vx_lTtpugvGP3SJm3mi3kOqqbqtGH2VoD7BHXtziaQrQ39NBTKV5Qt0FNqmMXZnAuJFBLk2-hiOBDCWPz6Xf7VqzFPpPSMkSvQSAp7rEPXvSAmKQLvn7lNA-9ue6YPnh_4XQmExEoQLajSsI0Qhk6DhTg0vOUflEi76EUJ-eNc5rLiskz9tSAFEwQLQ">
          </pet-scroller>
        
        {/* <div className='pf-div'><p>Sorry. We're having trouble loading the kitties. Pleas try back again soon.</p></div> */}
        </>
    );
}

export default Petfinder;
