import React from 'react';
import './Buttonlink.css';

export default function Buttonlink({ text, href, newPage }) {

    return (
        <a 
           className="rounded-button" 
           href={href}
           target={newPage ? "_blank" : "_self"}
           rel={newPage ? "noopener noreferrer" : ""}
        >
            {text}
        </a>
    );
}