import './Viewpdf.css'
import Buttonlink from '../Buttonlink/Buttonlink';

function Viewpdf({ filename }) {
  return (
    <>
      <Buttonlink
        text="View PDF"
        href={filename}
        newPage={true}
      />
    </>
  )
}

export default Viewpdf;