// import GoogleLoginButton from "../GoogleLoginButton/GoogleLoginButton.js";
import LoginCognito from "../LoginCognito/LoginCognito.js";
// import FacebookLoginButton from "../FacebookLoginButton/FacebookLoginButton.js";
import "./Login.css";

function Login() {
  return (
    <div className="login-bgimg">
      <div className="login-div">
        <h1>Login / Sign-Up</h1>
          <LoginCognito />
        {/* <div className="login-btn">
          <GoogleLoginButton/>
        </div> */}
        {/* <div className="login-btn">
          <FacebookLoginButton/>
        </div> */}
      </div>
    </div>  
  );
}

export default Login;