import React, { useState, useEffect } from 'react';
import './DropDownMenu.css';

const DropDownMenu = ({ label, options, required, id, onSelectedChange, defaultValue }) => {
  const [choice, setChoice] = useState('');
  const [otherInput, setOtherInput] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);

  // Set initial value without triggering onChange
  useEffect(() => {
    if (!isInitialized) {
      if (defaultValue) {
        setChoice(defaultValue);
      }
      setIsInitialized(true);
    }
  }, [defaultValue, isInitialized]);

  // Call onSelectedChange when choice changes, but only after initialization
  useEffect(() => {
    if (isInitialized && typeof onSelectedChange === 'function') {
      if(choice === 'Other'){
        onSelectedChange(otherInput);
      } else {
        onSelectedChange(choice);
      }
    }
  }, [choice, otherInput, onSelectedChange, isInitialized]);

  const handleChoiceChange = (event) => {
    setChoice(event.target.value);
    if(event.target.value !== 'Other'){
      setOtherInput('');
    }
  };

  const handleOtherInputChange = (event) => {
    setOtherInput(event.target.value);
  };

  let finalOptions = options.includes(defaultValue) ? options : [defaultValue].concat(options);

  return (
    <div>
        <label>{label}</label>
        <select value={choice || defaultValue} onChange={handleChoiceChange} required={required} id={id}>
          {
            (defaultValue ? finalOptions : options).map((option, index) =>
              <option key={index} value={option}>{option}</option>
            )
          }
        </select>
        <div>
          {choice === "Other" && <input type="text" value={otherInput} onChange={handleOtherInputChange}/>}
        </div>
        <div className="RequiredDropDown">
          {(required && choice === "") && <span>*This field is required.</span>}
        </div>
    </div>
  );
};

export default DropDownMenu;
