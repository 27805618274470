import './Donate.css'
import danny from '../../assets/images/danny.JPG'

function Donate() {
  return (
    <>
    <div className='new-page'>
      <div>
        <p>Thank you for your donation.</p>
      </div>
      <div>
        <p>A payment to <b>12INCHTAILS</b> will show up on your statement. </p>
        <img src={danny} alt="Danny" />
      </div>
    </div>
    </>
  )
}

export default Donate;