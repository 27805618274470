import axios from 'axios';

async function Get(endpoint){
  try {
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error occurred while making GET request:', error);
    throw error;
  }
}

export default Get;