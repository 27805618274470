import { useState, Fragment } from 'react';
import './Faq.css'

function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  }

  const faqs = [
    {
      question: "Where are you located?",
      answer: "We are located in the Atlantic City area of South Jersey."
    },
    {
      question: "What is up with the name 12 inch tails?",
      answer: "Great question! Throughout years of extensive research about cats, along with ChatGPT, we discovered that the average cats tail is about 12 inches long. Then we got to thinking that Nine Inch Nails is an awesome band and voila, we have 12 inch tails."
    },
    {
      question: "What services do you offer?",
      answer: "We love animals, so nearly any service that helps them, we can try to help with that. This includes rehoming, adoption, transportation to/from groomers, etc."
    },
    {
      question: "Do you provide services outside of the South Jersey area?",
      answer: "Obviously our location limits the services we can provide. However, if you reach out to us, we will help the best we can to find someone in your area to provide the same services."
    },
    {
      question: "How do I adopt a cat from your rescue?",
      answer: "Our adoption process starts with contacting us through our website. After you send you're contact info, we'll contact you to discuss the next steps, which typically include filling out an application, a meet-and-greet with the cat, and a home visit."
    },
    {
      question: "How do I become a foster?",
      answer: "The process is similar to adopting. It starts with contacting us through our website. After you send you're contact info, we'll contact you to discuss the next steps, which typically include filling out an application, a meet-and-greet with the cat, and a home visit."
    },
    {
      question: "What are the adoption fees, and what do they cover?",
      answer: "Adoption fees vary by age and breed but generally cover spay/neuter surgery, vaccinations, microchipping, and any medical treatment the cat has received. Detailed fee information is available on our website."
    },
    {
      question: "Can I adopt a cat if I live in an apartment?",
      answer: "Yes, many of our cats can thrive in an apartment setting. We'll help you find a cat suited to your living situation."
    },
    {
      question: "How long does the adoption process take?",
      answer: "The process can take from a few days to a couple of weeks, depending on various factors, including the completion of your application, the cat's readiness, and scheduling meet-and-greets."
    },
    {
      question: "What should I do to prepare my home for a new cat?",
      answer: "We recommend cat-proofing your home, setting up a designated safe space for your new cat, and having essentials like a litter box, food and water dishes, and toys ready."
    },
    {
      question: "What are the requirements to become a foster parent?",
      answer: "Foster parents must have a safe, cat-friendly environment, be able to transport the cat to veterinary appointments if needed, and follow our guidelines for care and socialization."
    },
    {
      question: "How long will I foster a cat?",
      answer: "The fostering duration can vary from a few weeks to several months, depending on the cat's needs and how quickly they find a forever home."
    },
    {
      question: "Does the rescue cover expenses for foster cats?",
      answer: "Yes, we cover all medical expenses and provide food, litter, and other supplies. Foster parents are encouraged to provide love and socialization."
      // answer: "You would be responsible for covering the cost of food or formula and litter, but we can help with providing food. We cover all medical expenses."
    },
    {
      question: "Can I foster a cat with a full-time job?",
      answer: "Absolutely! We'll match you with a cat suitable for your lifestyle and availability."
    },
    {
      question: "What happens if I want to adopt my foster cat?",
      answer: "Foster parents have the first option to adopt their foster cat. Let us know if you're interested, and we'll guide you through the adoption process."
    },
    {
      question: "How can I volunteer with your rescue?",
      answer: "We're always looking for volunteers! Please visit our website and contact us to inquire about the various ways you can help."
    },
    {
      question: "Can I donate items instead of money?",
      answer: "Yes, we gratefully accept donations of food, litter, toys, and other cat supplies. Check our website for a wish list of items we currently need."
    },
    {
      question: "Do you accept cats from the public?",
      answer: "We primarily rescue cats from high-risk situations and overcrowded shelters. If you need to rehome a cat, please contact us to discuss the situation, and we'll do our best to help."
    },
    {
      question: "How can I help if I can't adopt or foster?",
      answer: "Besides volunteering and donating, you can help by spreading the word about our rescue and our cats looking for homes. Sharing our posts on social media can make a big difference!"
    },
    {
      question: "Are all your cats spayed/neutered before adoption?",
      answer: "Yes, all cats are spayed or neutered before adoption. This is part of our commitment to reducing pet overpopulation."
    }
  ]

  return (
    <div className='new-page'>
      <h1>Frequently Asked <span className="q" style={{fontSize: '28px'}}>Q</span>uestions</h1>
      <div className="faq faq-line">
        {faqs.map((faq, index) => (
          <Fragment key={index}>
            <button className="accordion" onClick={() => handleAccordionClick(index)}>{faq.question}</button>
            <div className="panel" style={{maxHeight: activeIndex === index ? "400px" : "0"}}>
              <p>{faq.answer}</p>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default Faq;