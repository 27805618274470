import Post from "../RestHelpers/Post/Post";
import Get from "../RestHelpers/Get/Get";
import Put from "../RestHelpers/Put/Put";
import Delete from "../RestHelpers/Delete/Delete";

class IntakeHelper {
    static buildData(items, method) {
        if (typeof items !== 'object' || items === null || Array.isArray(items)) {
            throw new Error("Invalid input: items must be a non-null object.");
        }
    
        // Removing keys where value is null or empty string 
        // and removing keys where value is an empty array
        Object.keys(items).forEach(key => {
            if (items[key] == null || items[key] === '' || (Array.isArray(items[key]) && items[key].length === 0)) {
                delete items[key];
            }
        });
    
        if (method === "POST") {
            return {
                "table_name": "adoptions",
                "primary_key": "adoptionid",
                "items": items
            };
        } else if (method === "PUT") {
            return items;
        }
    }

    static postIntake(items) {
        const data = this.buildData(items, "POST");
        console.log(data);
        return Post('https://azad20j2uc.execute-api.us-east-1.amazonaws.com/prod/dynamo', data);
    }

    static getIntake(id) {
        return Get(`https://azad20j2uc.execute-api.us-east-1.amazonaws.com/prod/dynamo?table_name=intake-table&id_name=intakeid&id=${id}`);
    }

    static getAllIntakes() {
        return Get('https://azad20j2uc.execute-api.us-east-1.amazonaws.com/prod/dynamo?table_name=intake-table')
            .then(data => 
                data.sort((a, b) => new Date(b.intakeDateTime) - new Date(a.intakeDateTime))
            );
    }

    static putIntake(id, items) {
        const data = this.buildData(items, "PUT");
        // console.log(data);
        return Put(`https://azad20j2uc.execute-api.us-east-1.amazonaws.com/prod/dynamo?table_name=intake-table&id_name=intakeid&id=${id}`, data);
    }

    static deleteIntake(id) {
        return Delete(`https://azad20j2uc.execute-api.us-east-1.amazonaws.com/prod/dynamo?table_name=intake-table&id_name=id&id=${id}`);
    }   
}

export default IntakeHelper;