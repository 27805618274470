import { useAuth } from "react-oidc-context";
import './LoginCognito.css'
// import { jwtDecode } from "jwt-decode";

function LoginCognito() {
  const auth = useAuth();

  // const signOutRedirect = () => {
  //   const clientId = "6cmmm5ra4pk8r8lveef69qvp03";
  //   const logoutUri = "https://localhost:3000";
  //   const cognitoDomain = "https://us-east-1vvddyxb5y.auth.us-east-1.amazoncognito.com";
  //   window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutUri)}`;
  // };

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Encountering error... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    // console.log(auth.user);
    // const userObject = jwtDecode(auth.user?.id_token);
    // console.log("JWTTOKEN:   " + JSON.stringify(userObject));
    // localStorage.setItem('user', JSON.stringify(auth.user?.profile));
    return (
      <div>
        <pre> Hello you are already logged in as: {auth.user?.profile.email} </pre>
        {/* <pre> ID Token: {auth.user?.id_token} </pre>
        <pre> Access Token: {auth.user?.access_token} </pre>
        <pre> Refresh Token: {auth.user?.refresh_token} </pre> */}

        {/* <button onClick={() => auth.removeUser()}>Sign out</button> */}
      </div>
    );
  }

  return (
    <div>
          <button style={{
            background: '#007BFF', 
            color: 'white',
            fontWeight: 'bold',
            borderRadius: '12px',
            padding: '10px 20px',
            border: 'none',
            fontSize: '15px',
            cursor: 'pointer'
          }} onClick={() => auth.signinRedirect({ target: '_self' })}>Sign up / Sign in</button>
  </div>
  );
}
  
export default LoginCognito;