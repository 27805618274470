import { React } from 'react';
// import axios from 'axios';
import './Contact.css';
// import Button from '../Button/Button';
// import Buttonlink from '../Buttonlink/Buttonlink';
// import ReCAPTCHA from "react-google-recaptcha"; 
// import Post from '../Helpers/RestHelpers/Post/Post';

function Contact() {
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");
  // const [message, setMessage] = useState("");
  // const [captchaValue, setCaptchaValue] = useState(null);
  // const [formSubmitted, setFormSubmitted] = useState(false);
  // const [captchaVerified, setCaptchaVerified] = useState(false);

  // const handleCaptchaPost = async (captchaValue) => {
  //   if (captchaValue !== "") { // add this line
  //     const payload = {
  //         token: captchaValue,
  //         siteKey: process.env.REACT_APP_GOOGLE_SITE_KEY,
  //     };

  //     // console.log("Payload: ", payload);

  //     await Post('https://3ww6hb45gh.execute-api.us-east-1.amazonaws.com/default/captcha-12inchtails', payload)
  //       .then((res) => {
  //         // console.log(res.tokenProperties);
  //         setCaptchaVerified(res.tokenProperties.valid);
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //       });
  //   }
  // }

  // useEffect(() => { 
  //   if (captchaValue) {
  //     handleCaptchaPost(captchaValue); 
  //   }
  // }, [captchaValue]);

  // const handleFormSubmit = async (e) => {
  //   e.preventDefault();

  //   const payload = {
  //     name: name,
  //     email: email,
  //     phone: phone,
  //     message: message
  //   };

  //   await axios.post('https://p0vez52m8i.execute-api.us-east-1.amazonaws.com/prod/kklEmailForwarder', payload)
  //     .then((res) => {
  //       console.log(res.data);
  //       setFormSubmitted(true);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }

  if(true){
    return (
      <div className='new-page'>
        <h1>You can reach us at <a href="mailto:12inchtails@gmail.com">12inchtails@gmail.com</a></h1>
        <br></br>
        {/* <Buttonlink href="/home" text="Home"/>
        <br></br>
        <br></br>
        <p>We will be getting back to you shortly.</p> */}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>
    )
  } else {
    return (
      <div className='new-page'>
        <h1>Contact Us</h1>
        {/* <p>Please fill out the form below to volunteer, foster, adopt, or any other inquiry.</p> */}
        {/* <form onSubmit={handleFormSubmit} className='contact-form'>
          <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Name*" required/>
          <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email*" required/>
          <input type="tel" value={phone} onChange={e => setPhone(e.target.value)} placeholder="Phone"/>
          <textarea value={message} onChange={e => setMessage(e.target.value)} placeholder="Message*" required></textarea> */}
          {/* <button type="submit">Send</button> */}

          {/* {captchaVerified ? (
            <Button buttonText="Send" color="#069555"/>
          ) :(
            <div>
              <p style={{color: "red"}}>We do apologize but we require you to verify that you're not a robot before you can click Submit.</p>
              <p>This helps 12 Inch Tails protect your information.</p>
            </div>
          )} */}
        {/* </form> */}
        <br></br>
        <br></br>
        {/* <div className='captcha'>
          <ReCAPTCHA sitekey='6LeLi68qAAAAAPGdqA7ijaGLTdwVN-qzouN6Vjm_' 
                      onChange={value => {
                          setCaptchaValue(value);
                          // console.log('Captcha: ', value);
            }} />
        </div> */}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>
    );
  }
}

export default Contact;