import { React } from 'react';
import './Home.css';
import HomeBackground from '../HomeBackground/HomeBackground'
import About from '../About/About'
import Linknav from '../Linknav/Linknav';
import logo from '../../assets/images/transparent_logo.png';

function Home() {

  return (
    <>
    <HomeBackground />

    <div className='home-header home-bgimg'>
      <h2>A South Jersey Cat Rescue</h2>
      <img src={logo} style={{ width: '340px', height: '340px'}} alt="12inchtails" />
      <h1>How Can You Help?</h1>
      <Linknav 
        links={[
          { href: "/waystogive", text: "Donate" },
          { href: "https://www.amazon.com/hz/wishlist/ls/1AF2KL6LHTS3V?ref_=wl_share", text: "Wish List", external: true },
          { href: "/followus", text: "Follow Us" },
          { href: "/foster", text: "Foster" },
          { href: "/adopt", text: "Adopt" },
          { href: "/volunteer", text: "Volunteer" }
        ]}
      />
      <p></p>
    </div>
    <About />
    </>
  );
}

export default Home;