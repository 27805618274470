import './Footer.css'
// import Socialmedia from '../Socailmedia/Socialmedia';

function Footer() {
  return (
    <>
    <footer>
      <p>
        <b>12 Inch Tails Animal Rescue - a 501c (3) NJ Nonprofit Corporation - All Rights Reserved</b>
      </p>
    </footer>

    </>
  )
}

export default Footer;