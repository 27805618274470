import axios from 'axios';

async function Post(endpoint, data){
  // console.log(data);
  try {
    const response = await axios.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('Error occurred while making POST request:', error);
    throw error;
  }
}

export default Post;