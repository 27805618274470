// import { useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import { useAuth } from "react-oidc-context";
import './Logout.css';

function Logout() {

  // const navigate = useNavigate();

  const auth = useAuth();

  const signOutRedirect = () => {
    const clientId = "6cmmm5ra4pk8r8lveef69qvp03";
    const logoutUri = "https://localhost:3000";
    const cognitoDomain = "https://us-east-1vvddyxb5y.auth.us-east-1.amazoncognito.com";
    window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutUri)}`;
  };

  const handleLogout = () => {
    // localStorage.setItem('user', null);
    // localStorage.setItem('role', null);
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    auth.removeUser()
    signOutRedirect({ target: '_self' });
    // Redirect to the login page (or wherever you'd like)
    // navigate('/');
    // window.location.reload();
  }

  return (
    <div className="logout-bgimg">
        <div className="logout-div">
            <h1>Logout</h1>
            <h3>Are you sure you want to logout?</h3>
            {/* <button onClick={handleLogout}>Logout</button> */}
            <Button buttonText="Logout" color="green" onClick={handleLogout}/>
            <br></br>
            <h4>Thank you for visiting 12inchtails!</h4>
            <h4>Have a great day!</h4>
            {/* <p>Made with ❤️ by <a href="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX" target="_blank" rel="noopener noreferrer">Matthew Flesher</a></p> */}
        </div>   
    </div>  
  );
}

export default Logout;