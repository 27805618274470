import './About.css'

function About() {
  return (
    <div id="about" className="about-div">
      <div className="content-wrapper">
      <h1>About Us</h1>
        <p>
        Twelve Inch Tails Animal Rescue, deeply devoted to the well-being of homeless and needy animals, lovingly serves the communities of South Jersey, with a special focus on Atlantic City, Absecon, Galloway Township, Egg Harbor City, and Egg Harbor Township.
        </p>
        <p>Our heart lies in rescuing and rehoming cats, addressing their unique needs. 
          We offer adoption and fostering <a className="all-a" href='/services'>services</a> as well as TNR (Trap, Neuter, Return) and TNA (Trap, Neuter, Assess). We are deeply invested in the individual well-being and future of each animal. We dedicate ourselves to helping adoptable cats and kittens transition from the challenging life on the streets to the warm and loving embrace of a forever home.
        </p>
        <p>At Twelve Inch Tails Animal Rescue, we embrace a compassionate approach, diverging from the traditional shelter model. Our rescued animals are nurtured in a network of caring foster homes, providing them an environment that mirrors a typical family home. This nurturing, family-like atmosphere is essential for giving our animals the affection, care, and social experiences they need. Our work, driven by love and dedication, is anchored in the belief that every animal deserves a chance for a happier, more loving life.
        </p>
        <p>If you have any questions, please look at our <a className="all-a" href='/faq'>FA<span className="q" style={{fontSize: '14px'}}>Q</span></a> page
            or feel free to <a className="all-a" href='/contact'>contact</a> us.
          </p>
        </div>
        <br></br>
    </div>
  )
}

export default About;