import { useEffect, useState } from 'react';  // Import `useState` hook
import './UserAccount.css'
import Linknav from '../Linknav/Linknav';
import Post from '../Helpers/RestHelpers/Post/Post';
// import Put from '../Helpers/RestHelpers/Put/Put'; // import Put 
import Get from '../Helpers/RestHelpers/Get/Get'; // import Get
import Buttonlink from '../Buttonlink/Buttonlink';
import { useAuth } from "react-oidc-context";

function UserAccount() {

  const auth = useAuth();

  const [userEmail, setUserEmail] = useState('');  // Initialize state
  // const [givenName, setGivenName] = useState('');

  useEffect(() => {
    if (auth.isAuthenticated) {
      // console.log(auth.user);
      localStorage.setItem('user', JSON.stringify(auth.user?.profile));
      const userData = JSON.parse(localStorage.getItem('user')); // Parsing the string into JavaScript object
      setUserEmail(userData.email);
    }
  }, [auth]);

  const BASE_URL = 'https://azad20j2uc.execute-api.us-east-1.amazonaws.com/prod/dynamo';
  const TABLE_NAME = 'user-table';
  const ID_NAME = 'username';

  const fetchUserAndUpdateOrInsert = async (email) => {

    if(!email) return;
    
    const data = {
      "table_name": "user-table",
      "primary_key": "username",
      "items": {
        "username": email,
      }
    }

    try {
      const user = await Get(`${BASE_URL}?table_name=${TABLE_NAME}&id_name=${ID_NAME}&id=${email}`);
      if (user) {
        console.log('User already exists:', user);
        // localStorage.setItem('role', user.role);
        // await Put(BASE_URL, data);
      } else {
        await Post(BASE_URL, data);
      }
    } catch (error) {
      console.error('Error checking user:', error);
    }
  }

  useEffect(() => {
    fetchUserAndUpdateOrInsert(userEmail);
  }, [userEmail]);

  return (
    <>
    <div className='user-account-bgimg'>
      <div id="user-account" className='user-account-div'>
        <br></br>
        <br></br>
        {/* <a href='home'>Home</a> */}
        <br></br>
        <h1>Thank you for being a part of 12inchtails!</h1>
        <div>
            {/* <p><a href="/logout">Logout</a></p> */}
            <p>
                <Buttonlink text="Home" href="/home"/>
            </p>
        </div>
        <h3>We are passionate about helping the kitties.</h3>
        <h3> Below are a few ways you can help or <a href="/home">click here </a> to learn more <a href="/home">about </a> us.</h3> {/* Displaying the user email */}
        {/* <p>Your name is: {givenName}</p> Displaying the user email */}
        <br></br>
        <br></br>
        <br></br>
          <Linknav 
            links={[
              { href: "/waystogive", text: "Donate" },
              { href: "/foster", text: "Foster" },
              { href: "/adopt", text: "Adopt" },
            ]}
          />
          <br></br>
          <br></br>
          <br></br>
        <br></br>
          <div>
            {/* <p><a href="/logout">Logout</a></p> */}
            <p>
                <Buttonlink text="Logout" href="/logout"/>
            </p>
        </div>
        <br></br>
        <br></br>
        <br></br>
        </div>
      </div>
    </>
  )
}

export default UserAccount;