import './Socialmedia.css'
import fb from '../../assets/images/buttons/fb.png';
import ig from '../../assets/images/buttons/ig.png';
import tiktok from '../../assets/images/buttons/tt.png';
import youtube from '../../assets/images/buttons/youtube.png';


function Socialmedia() {
  return (
      <>
      <div className="social">
      <a href="https://www.facebook.com/profile.php?id=61556340613222" target="_blank" rel="noopener noreferrer">
            <img src={fb} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/12inchtails/" target="_blank" rel="noopener noreferrer">
              <img src={ig} alt="Instagram"/>
          </a>
          <a href="https://www.tiktok.com/@12inchtails?fbclid=IwAR2PPUJPxMw1uBFHLNPaVgYR9Fglc_Qyu4iYHYKuqzFG3yoX3Ca425LgVQs" target="_blank" rel="noopener noreferrer">
              <img src={tiktok} alt="TikTok"/>
          </a>
          <a href="https://www.youtube.com/@12inchtails" target="_blank" rel="noopener noreferrer">
              <img src={youtube} alt="YouTube"/>
          </a>
      </div>
      </>
  )
}

export default Socialmedia;
