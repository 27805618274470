import { React, Fragment } from 'react';
import './Linknav.css';

function Linknav({ links }) {
  return (
    <div className='linknav'>
      <div className="rounded-section">
        {links.map((link, index) => (
          <Fragment key={index}>
            <a 
              href={link.href} 
              target={link.external ? "_blank" : "_self"} 
              rel={link.external ? "noopener noreferrer" : ""}
              className={
                links.length === 3 
                  ? (index === 0 ? "first-3" : (index === links.length - 1) ? "last-3" : "")
                  : (index === 0 ? "first" : (index === links.length - 1) ? "last" : "")
              }
            >
              {link.text}
            </a>
            {index < links.length - 1 && <div className="separator"></div>}
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export default Linknav;