import './Foster.css'
import Viewpdf from '../Viewpdf/Viewpdf';
import foster_form from '../../assets/pdfs/12InchTails_Foster_Application_Agreement.pdf'

function Foster() {
  return (
    <>
      <div className='foster-bgimg'>
      <div className='foster-div'>
        <div className='content-wrapper'>
        <h1>Foster</h1>
        <p>
        Every year, the cat population in South Jersey faces a crisis straight out of a feline thriller movie, with hundreds of whiskered wonders, from the tiniest of kittens to the wisest of adult cats, teetering on the edge of a fate we dare not speak of. But fear not! You can play the hero in this tail, and here's how: fostering!
        </p>
        <p>
        Imagine yourself as a superhero in a cape (optional, but highly encouraged), swooping in to save the day. By opening your home to a cat in need, you're not just giving them a cozy spot to crash; you're setting them on their VIP journey to finding their forever home.
        </p>
        <p>
        Fostering is like being a backstage crew for the stars of the show, the cats! You help them shine, work on their purr-formance, and get them ready for their big debut on the adoption stage. Want to know more about this heroic role? 
        Check out our <a className="all-a" href='/faq'>FA<span className="q" style={{fontSize: '14px'}}>Q</span></a> page, where we spill all the tea on how you can become a part of the feline foster league.
        </p>
        <p>
        So, if you've got a little room in your home and a lot of love in your heart, consider fostering. It's your chance to be part of an epic adventure and make a real difference in the lives of South Jersey's most adorable residents.
        </p>
        <p>
          View <strong>foster form below</strong> and 
          <a className="all-a" href='/contact'> Contact</a> us if you're interested.
        </p>
        <p>
        <Viewpdf filename={foster_form}/>
        </p>
      </div>
      </div>
      </div>
    </>
  )
}

export default Foster;